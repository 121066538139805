import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { themes } from 'config/themes';
import { PageTitle } from 'docs/helpers/PageTitle';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Timeline, StraddledTimeline, TimelineDividerItem, TimelineMarkerItem } from '../index';
import { items } from './helpers/items';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="compositions/Timeline" title="Timeline" mdxType="PageTitle" />
    <h2 {...{
      "id": "cozy-timeline"
    }}>{`Cozy timeline`}</h2>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <Timeline items={items} spacing=\"cozy\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      themes,
      PageTitle,
      ThemeWrapper,
      Timeline,
      StraddledTimeline,
      TimelineDividerItem,
      TimelineMarkerItem,
      items,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Timeline items={items} spacing="cozy" mdxType="Timeline" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "comfy-timeline"
    }}>{`Comfy timeline`}</h2>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <Timeline items={items} spacing=\"comfy\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      themes,
      PageTitle,
      ThemeWrapper,
      Timeline,
      StraddledTimeline,
      TimelineDividerItem,
      TimelineMarkerItem,
      items,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Timeline items={items} spacing="comfy" mdxType="Timeline" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "unspaced-timeline"
    }}>{`Unspaced timeline`}</h2>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <Timeline items={items} />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      themes,
      PageTitle,
      ThemeWrapper,
      Timeline,
      StraddledTimeline,
      TimelineDividerItem,
      TimelineMarkerItem,
      items,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Timeline items={items} mdxType="Timeline" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "right-side-timeline"
    }}>{`Right side timeline`}</h2>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <Timeline items={items} spacing=\"cozy\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      themes,
      PageTitle,
      ThemeWrapper,
      Timeline,
      StraddledTimeline,
      TimelineDividerItem,
      TimelineMarkerItem,
      items,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Timeline items={items} spacing="cozy" mdxType="Timeline" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "right-side-timeline-1"
    }}>{`Right side timeline`}</h2>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <Timeline items={items} spacing=\"cozy\" />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      themes,
      PageTitle,
      ThemeWrapper,
      Timeline,
      StraddledTimeline,
      TimelineDividerItem,
      TimelineMarkerItem,
      items,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Timeline items={items} spacing="cozy" mdxType="Timeline" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "left-side-timeline"
    }}>{`Left side timeline`}</h2>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <Timeline\n    items={items.map(item => ({ ...item, position: \'left-center\' }))}\n    spacing=\"cozy\"\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      themes,
      PageTitle,
      ThemeWrapper,
      Timeline,
      StraddledTimeline,
      TimelineDividerItem,
      TimelineMarkerItem,
      items,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Timeline items={items.map(item => ({ ...item,
          position: 'left-center'
        }))} spacing="cozy" mdxType="Timeline" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "straddled-timeline"
    }}>{`Straddled timeline`}</h2>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <StraddledTimeline\n    items={items.map((item, index) => ({\n      ...item,\n      position: index % 2 ? \'left-center\' : \'right-center\',\n    }))}\n    leftWidth={260}\n    rightWidth={180}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      themes,
      PageTitle,
      ThemeWrapper,
      Timeline,
      StraddledTimeline,
      TimelineDividerItem,
      TimelineMarkerItem,
      items,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StraddledTimeline items={items.map((item, index) => ({ ...item,
          position: index % 2 ? 'left-center' : 'right-center'
        }))} leftWidth={260} rightWidth={180} mdxType="StraddledTimeline" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "straddled-timeline-manual-alternative"
    }}>{`Straddled timeline (manual alternative)`}</h2>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <Timeline\n    items={items.map((item, i) => ({\n      ...item,\n      position: i % 2 ? \'left-center\' : \'right-center\',\n      style: {\n        width: 180,\n        [i % 2 ? \'marginRight\' : \'marginLeft\']: 169,\n      },\n    }))}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      themes,
      PageTitle,
      ThemeWrapper,
      Timeline,
      StraddledTimeline,
      TimelineDividerItem,
      TimelineMarkerItem,
      items,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Timeline items={items.map((item, i) => ({ ...item,
          position: i % 2 ? 'left-center' : 'right-center',
          style: {
            width: 180,
            [i % 2 ? 'marginRight' : 'marginLeft']: 169
          }
        }))} mdxType="Timeline" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "custom-styles"
    }}>{`Custom styles`}</h2>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <Timeline\n    items={items.map((item, index) => ({\n      ...item,\n      color: undefined,\n      position: \'right-center\',\n      style: {\n        \'--timeline-item-connector-color\':\n          themes.light[\'primary-palette-border-color\'],\n        \'--timeline-item-state-color\':\n          themes.light[`color-P${index * 5 + 30}`],\n        \'--shade-primary-color\': themes.light[`color-P${index * 5 + 30}`],\n        \'--shade-opaque-primary-color\':\n          themes.light[`color-P${index * 5 + 30}-shade`],\n        \'--status-bubble-state-color\':\n          themes.light[`color-P${index * 5 + 30}`],\n        \'--status-bubble-state-contrast-color\':\n          themes.light[`color-P${index * 5 + 30}-contrast`],\n        \'--status-bubble-triangle-color\':\n          themes.light[`color-P${index * 5 + 30}-shade`],\n        \'--status-bubble-triangle-border-color\':\n          themes.light[`color-P${index * 5 + 30}`],\n      },\n      unthemed: true,\n    }))}\n    spacing=\"cozy\"\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      themes,
      PageTitle,
      ThemeWrapper,
      Timeline,
      StraddledTimeline,
      TimelineDividerItem,
      TimelineMarkerItem,
      items,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Timeline items={items.map((item, index) => ({ ...item,
          color: undefined,
          position: 'right-center',
          style: {
            '--timeline-item-connector-color': themes.light['primary-palette-border-color'],
            '--timeline-item-state-color': themes.light[`color-P${index * 5 + 30}`],
            '--shade-primary-color': themes.light[`color-P${index * 5 + 30}`],
            '--shade-opaque-primary-color': themes.light[`color-P${index * 5 + 30}-shade`],
            '--status-bubble-state-color': themes.light[`color-P${index * 5 + 30}`],
            '--status-bubble-state-contrast-color': themes.light[`color-P${index * 5 + 30}-contrast`],
            '--status-bubble-triangle-color': themes.light[`color-P${index * 5 + 30}-shade`],
            '--status-bubble-triangle-border-color': themes.light[`color-P${index * 5 + 30}`]
          },
          unthemed: true
        }))} spacing="cozy" mdxType="Timeline" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <h3 {...{
      "id": "timeline"
    }}>{`Timeline`}</h3>
    <Props of={Timeline} mdxType="Props" />
    <h3 {...{
      "id": "straddledtimeline"
    }}>{`StraddledTimeline`}</h3>
    <Props of={StraddledTimeline} mdxType="Props" />
    <h3 {...{
      "id": "timelinedivideritem"
    }}>{`TimelineDividerItem`}</h3>
    <Props of={TimelineDividerItem} mdxType="Props" />
    <h3 {...{
      "id": "timelinemarkeritem"
    }}>{`TimelineMarkerItem`}</h3>
    <Props of={TimelineMarkerItem} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      